<template>
  <div>
    <p>业务国际化：{{ $t('demo.text') }}</p>
    <div class="block">
      <span class="demonstration">组件国际化：</span>
      <el-date-picker
        v-model="value1"
        type="datetime"
        placeholder="选择日期时间"
      />
    </div>
    <label>
      切换语言：
      <select v-model="$i18n.locale">
        <option value="zh-CN">中文简体</option>
        <option value="zh-TW">繁体中文</option>
        <option value="en">English</option>
      </select>
    </label>
  </div>
</template>

<script>
export default {
  name: 'HelloI18n',
  data () {
    return {
      value1: ''
    }
  }
}
</script>
