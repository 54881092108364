<template>
  <div style="text-align: center">
    <img src="../../assets/images/logo.png" alt="">
    <h1>ylh-web-base</h1>
    <HelloI18n/>
  </div>
</template>

<script>
import HelloI18n from './components/HelloI18n'

export default {
  name: 'Demo',
  components: {
    HelloI18n
  },
  methods: {}
}
</script>
